import breakpoints from "@vertikal/e-prospera.styles.breakpoints";
import Colors, { pureColors } from "@vertikal/e-prospera.styles.colors";
import Text from "@vertikal/e-prospera.ui.text";
import styled from "styled-components";

export const ChangingTheWorldDivider = styled.div`
  @media ${breakpoints.md} {
    width: 33%;
    margin: 0 auto;
  }
`;

export const ESGImpactDivider = styled.div`
  flex-shrink: 0;
  width: 100%;
  @media ${breakpoints.md} {
    width: 3px;
  }
`;

export const ESGImpactGrid = styled.div`
  margin-bottom: 13rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-items: center;
  width: 100%;
  text-align: center;
  @media ${breakpoints.md} {
    flex-direction: row;
  }
`;

export const ChangingTheWorld = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  gap: 2rem;
  color: ${pureColors.white};
`;

export const AboutHero = styled.div`
  margin-top: 5rem;
`;

export const PoweredByEprospera = styled.div`
  color: ${pureColors.white};
  gap: 4rem;
  display: flex;
  flex-direction: column;
  text-align: center;
`;

export const PoweredByEprosperaInfo = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 2rem;
  > p {
    width: 100%;
  }
  @media ${breakpoints.md} {
    gap: 4rem;
    flex-direction: row;
  }
`;
export const EvolutionInEGovernance = styled.div`
  display: flex;
  flex-direction: column;
  h3 {
    margin-top: 2rem;
  }
  @media ${breakpoints.md} {
    flex-direction: row;
  }
`;
export const ChangingTheWorldInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  @media ${breakpoints.md} {
    gap: 4rem;
    flex-direction: row;
  }
`;

export const Highlight = styled(Text)`
  color: ${Colors.success.c400};
  text-align: center;
  font-weight: 600;
  font-size: 30px;
  line-height: 40px;
  font-family: "Avenir Next";
  font-style: normal;
  letter-spacing: -0.3px;
  @media ${breakpoints.md} {
    margin-top: 15%;
    font-size: 52px;
    line-height: 60px;
    letter-spacing: -1px;
  }
`;
