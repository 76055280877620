import { Angles } from "@vertikal/e-prospera.ui.angles";
import Box from "@vertikal/e-prospera.ui.box";
import EPLink from "@vertikal/e-prospera.ui.link";
import Text from "@vertikal/e-prospera.ui.text";
import { graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import Helmet from "react-helmet";
import { Trans, useTranslation } from "react-i18next";
import { Breadcrumbs } from "../components/Breadcrumbs/Breadcrumbs";
import DottedDivider from "../components/DottedDivider/DottedDivider";
import GlobalSettings from "../components/GlobalSettings";
import MainMenu from "../components/MainMenu/MainMenu";
import {
  AboutHero,
  ChangingTheWorld,
  ChangingTheWorldDivider,
  ChangingTheWorldInfo,
  ESGImpactDivider,
  ESGImpactGrid,
  EvolutionInEGovernance,
  Highlight,
  PoweredByEprospera,
  PoweredByEprosperaInfo,
} from "../styles/about.styles";
import { PartnerItem, PartnersGrid } from "../styles/business.styles";
import {
  CTAButton,
  FooterSection,
  TextHighLight,
} from "../styles/index.styles";
import { AccentBlock, ContentWrapper } from "../styles/residency.styles";

const About = () => {
  const [t] = useTranslation();
  return (
    <>
      <Helmet>
        <title>{t("public.about.title")}</title>
        <meta name="title" content={t("public.about.meta.title")} />
        <meta name="description" content={t("public.about.meta.description")} />
        <link rel="canonical" href="/tax" />
      </Helmet>
      <GlobalSettings />
      <MainMenu darkFooter>
        <AccentBlock paddingTop={1} paddingBottom={6} $color="success">
          <ContentWrapper $wide>
            <Breadcrumbs theme="dark">
              <Link to="/">
                <Trans>common.home</Trans>
              </Link>
              <span>
                <Trans>common.about</Trans>
              </span>
            </Breadcrumbs>
          </ContentWrapper>
          <ContentWrapper>
            <AboutHero>
              <Box marginBottom={3}>
                <Text variant="h2" as="h1">
                  <Trans>public.about.hero.title</Trans>
                </Text>
              </Box>
              <Box
                marginBottom={4}
                display="flex"
                flexDirection="column"
                gap="1.5"
              >
                <Text variant="h4" as="h2">
                  <Trans>public.about.hero.subtitle_1</Trans>
                </Text>
                <Text>
                  <Trans>public.about.hero.subtext_1</Trans>
                </Text>
              </Box>
              <Box display="flex" flexDirection="column" gap="1.5">
                <Text variant="h4" as="h2">
                  <Trans>public.about.hero.subtitle_2</Trans>
                </Text>
                <Text>
                  <Trans>public.about.hero.subtext_2</Trans>
                </Text>
              </Box>
            </AboutHero>
          </ContentWrapper>
        </AccentBlock>
        <Angles noHighlight invertColors matchGreen flipVertical />
        <AccentBlock $color="accent" paddingTop={6} paddingBottom={6}>
          <ContentWrapper>
            <PoweredByEprospera>
              <Text variant="h2">
                <Trans>public.about.powered_by.title</Trans>
              </Text>
              <Text>
                <Trans>public.about.powered_by.subtitle</Trans>
              </Text>
              <Box>
                <StaticImage
                  src="../images/about/powered-by-eprospera.svg"
                  width={762}
                  height={286}
                />
              </Box>
              <PoweredByEprosperaInfo>
                <Text>
                  <Trans>public.about.powered_by.info_1</Trans>
                </Text>
                <Text>
                  <Trans>public.about.powered_by.info_2</Trans>
                </Text>
              </PoweredByEprosperaInfo>
            </PoweredByEprospera>
          </ContentWrapper>
        </AccentBlock>
        <Angles />
        <AccentBlock paddingTop={6} paddingBottom={6}>
          <ContentWrapper>
            <EvolutionInEGovernance>
              <Box width="100%" textAlign="center">
                <StaticImage
                  src="../images/about/evolution-in-egovernence.svg"
                  alt=""
                  width={432}
                  height={301}
                />
              </Box>
              <Box display="flex" flexDirection="column" gap="2" width="100%">
                <Text variant="h3">
                  <Trans>public.about.evolution_in_egovernance.title</Trans>
                </Text>
                <Text>
                  <Trans>public.about.evolution_in_egovernance.info_1</Trans>
                </Text>
                <Text>
                  <Trans>public.about.evolution_in_egovernance.info_2</Trans>
                </Text>
              </Box>
            </EvolutionInEGovernance>
          </ContentWrapper>
        </AccentBlock>
        <Angles flipVertical />
        <AccentBlock paddingTop={6} paddingBottom={6} $color="accent">
          <ContentWrapper>
            <ChangingTheWorld>
              <Box>
                <StaticImage
                  src="../images/duotone-icons/world.svg"
                  alt=""
                  width={96}
                  height={96}
                />
              </Box>
              <Text variant="h3">
                <Trans>public.about.changing_the_world.title</Trans>
              </Text>
              <ChangingTheWorldDivider>
                <DottedDivider />
              </ChangingTheWorldDivider>
              <ChangingTheWorldInfo display="flex" gap="4" textAlign="left">
                <Text>
                  <Trans>public.about.changing_the_world.point_1</Trans>
                </Text>
                <Text>
                  <Trans>public.about.changing_the_world.point_2</Trans>
                </Text>
              </ChangingTheWorldInfo>
            </ChangingTheWorld>
          </ContentWrapper>
        </AccentBlock>
        <Angles />
        <AccentBlock paddingTop={6} paddingBottom={6}>
          <ContentWrapper>
            <Box marginBottom={4} gap="2" display="flex" flexDirection="column">
              <Text variant="h3" as="h2">
                <Trans>public.about.esg_impact.title</Trans>
              </Text>
              <Text>
                <Trans>public.about.esg_impact.subtitle</Trans>
              </Text>
            </Box>
            <ESGImpactGrid>
              <Box width="100%">
                <Highlight>500</Highlight>
                <Text>
                  <Trans>public.about.esg_impact.jobs_created</Trans>
                </Text>
              </Box>
              <ESGImpactDivider>
                <DottedDivider mobile="horizontal" direction="vertical" />
              </ESGImpactDivider>
              <Box width="100%">
                <Highlight>10,000</Highlight>
                <Text>
                  <Trans>public.about.esg_impact.jobs_expected</Trans>
                </Text>
              </Box>
              <ESGImpactDivider>
                <DottedDivider mobile="horizontal" direction="vertical" />
              </ESGImpactDivider>
              <Box width="100%">
                <Highlight>10+</Highlight>
                <Text>
                  <Trans>public.about.esg_impact.years_of_construction</Trans>
                </Text>
              </Box>
              <ESGImpactDivider>
                <DottedDivider mobile="horizontal" direction="vertical" />
              </ESGImpactDivider>
              <Box width="100%">
                <Highlight>90%</Highlight>
                <Text>
                  <Trans>public.about.esg_impact.workforce</Trans>
                </Text>
              </Box>
            </ESGImpactGrid>
            <Box marginBottom={3}>
              <Text variant="h3" as="h2">
                <Trans>public.business.partners.title</Trans>
              </Text>
            </Box>
            <PartnersGrid>
              <PartnerItem>
                <Box marginBottom={1.5} textAlign="center">
                  <StaticImage
                    width={190}
                    height={96}
                    alt=""
                    src="../images/business/zaha-hadid.png"
                  />
                </Box>
                <Text variant="p-sm">
                  <Trans>public.business.partners.partner_1.title</Trans>
                </Text>
                <Text color="gray" variant="p-xs">
                  <Trans>public.business.partners.partner_1.subtitle</Trans>
                </Text>
              </PartnerItem>
              <PartnerItem>
                <Box marginBottom={1.5} textAlign="center">
                  <StaticImage
                    width={190}
                    height={96}
                    alt=""
                    src="../images/business/ey-logo.png"
                  />
                </Box>
                <Text variant="p-sm">
                  <Trans>public.business.partners.partner_2.title</Trans>
                </Text>
                <Text color="gray" variant="p-xs">
                  <Trans>public.business.partners.partner_2.subtitle</Trans>
                </Text>
              </PartnerItem>
              <PartnerItem>
                <Box marginBottom={1.5} textAlign="center">
                  <StaticImage
                    width={190}
                    height={96}
                    alt=""
                    src="../images/business/island-concrete-logo.png"
                  />
                </Box>
                <Text variant="p-sm">
                  <Trans>public.business.partners.partner_3.title</Trans>
                </Text>
                <Text color="gray" variant="p-xs">
                  <Trans>public.business.partners.partner_3.subtitle</Trans>
                </Text>
              </PartnerItem>
              <PartnerItem>
                <Box marginBottom={1.5} textAlign="center">
                  <StaticImage
                    width={190}
                    height={96}
                    alt=""
                    src="../images/business/hs-urbanismo-logo.png"
                  />
                </Box>
                <Text variant="p-sm">
                  <Trans>public.business.partners.partner_4.title</Trans>
                </Text>
                <Text color="gray" variant="p-xs">
                  <Trans>public.business.partners.partner_4.subtitle</Trans>
                </Text>
              </PartnerItem>
              <PartnerItem>
                <Box marginBottom={1.5} textAlign="center">
                  <StaticImage
                    width={190}
                    height={96}
                    alt=""
                    src="../images/business/aca-logo.png"
                  />
                </Box>
                <Text variant="p-sm">
                  <Trans>public.business.partners.partner_5.title</Trans>
                </Text>
                <Text color="gray" variant="p-xs">
                  <Trans>public.business.partners.partner_5.subtitle</Trans>
                </Text>
              </PartnerItem>
              <PartnerItem>
                <Box marginBottom={1.5} textAlign="center">
                  <StaticImage
                    width={190}
                    height={96}
                    alt=""
                    src="../images/business/elite-logo.png"
                  />
                </Box>
                <Text variant="p-sm">
                  <Trans>public.business.partners.partner_6.title</Trans>
                </Text>
                <Text color="gray" variant="p-xs">
                  <Trans>public.business.partners.partner_6.subtitle</Trans>
                </Text>
              </PartnerItem>
            </PartnersGrid>
            <Box marginTop={4}>
              <Text>
                <Trans
                  components={{
                    a: (
                      <EPLink
                        id="business-register-search"
                        href="/business/search"
                      />
                    ),
                  }}
                >
                  public.about.partners.find_entities
                </Trans>
              </Text>
            </Box>
          </ContentWrapper>
        </AccentBlock>
        <Angles flipHorizontal flipVertical />
        <FooterSection>
          <Box textAlign="center">
            <Box marginBottom={2.5}>
              <Text variant="h2">
                <Trans components={{ highlight: <TextHighLight /> }}>
                  public.homepage.footer.title
                </Trans>
              </Text>
            </Box>
            <CTAButton
              id="join_now"
              color="main"
              href={process.env.GATSBY_REDIRECT_URL}
            >
              <Trans>common.join_now</Trans>
            </CTAButton>
          </Box>
        </FooterSection>
      </MainMenu>
    </>
  );
};

export default About;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
